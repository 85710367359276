import * as amplitude from '@amplitude/analytics-browser';
import TagManager from 'react-gtm-module';

const analyticsMiddleware = (store) => (next) => (action) => {
  const { analytics } = action;
  const { type } = action;


  if (!analytics || Array.isArray(type)) {
    return next(action);
  }

  if (analytics?.on != null && !type.includes(`_${analytics.on.toUpperCase()}`)) {
    return next(action);
  }

  if (analytics?.amplitude) {
    const { event, data } = analytics?.amplitude;

    if (process.env.NODE_ENV === 'production') {
      amplitude.track(event, data);
    }

  }

  if (analytics?.google) {
    const { event, data } = analytics?.google;

    TagManager.dataLayer({
      dataLayer: {
          ...data,
          event,
      }});
  }

  return next(action);
};

export default analyticsMiddleware;