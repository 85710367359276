function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  

  function formatJobURL(job) {
    const { _id, city, company, title } = job;
  
    const citySlug = removeAccents(city).toLowerCase().replace(/[^a-zA-Z]/g, '-');
    const companySlug =  company ? removeAccents(company).toLowerCase().replace(/[^a-zA-Z]/g, '-') : false;
    const titleSlug = removeAccents(title).toLowerCase().replace(/[^a-zA-Z]/g, '-');  
  
    let url = `/job/${titleSlug}${companySlug ? `-${companySlug}` : ''}-${citySlug}/${_id}`;
  
    url = url.replace(/-([a-z]{1,3})-/g, '-');
    url = url.replace(/-([a-z]{1,3})-/g, '-');
    url = url.replace(/-([a-z]{1,3})-/g, '-');
    url = url.replace(/-{2,}/g, '-');
  
    return url;
  }

  function formatRagElementURL(job) {
    const { _id, city, company, title } = job;
  
    const citySlug = removeAccents(city).toLowerCase().replace(/[^a-zA-Z]/g, '-');
    const companySlug =  company ? removeAccents(company).toLowerCase().replace(/[^a-zA-Z]/g, '-') : false;
    const titleSlug = removeAccents(title).toLowerCase().replace(/[^a-zA-Z]/g, '-');  
  
    let url = `/job/${titleSlug}${companySlug ? `-${companySlug}` : ''}-${citySlug}/${_id}`;
  
    url = url.replace(/-([a-z]{1,3})-/g, '-');
    url = url.replace(/-([a-z]{1,3})-/g, '-');
    url = url.replace(/-([a-z]{1,3})-/g, '-');
    url = url.replace(/-{2,}/g, '-');
  
    return url;
  }

  function getAgentFromUrl() {
    return `/${window.location.pathname.split('/')[1]}`
  }

  function parseOfferLink(text) {
      const patterns = [
          /https:\/\/www\.jj-tracker\.com\/fr\/redirect\/offer\/\d+\/([a-z0-9]*)/g,
          /https:\/\/candidat\.francetravail\.fr\/offres\/recherche\/detail\/([A-Za-z0-9]*)/g,
          /https:\/\/app\.jobypepper\.com\/#\/offer\/([a-zA-Z0-9]*)/g,
          /https:\/\/labonnealternance\.apprentissage\.beta\.gouv\.fr\/recherche-apprentissage\?type=matcha&itemId=([a-z0-9]*)/g
      ];
    
      const matches = [];
      let match;
    
      for (const pattern of patterns) {
          while ((match = pattern.exec(text)) !== null) {
              // Push the captured ID to the matches array
              matches.push(match[1]);
          }
      }
    
      return matches;
  }

  export { formatJobURL, formatRagElementURL, getAgentFromUrl, parseOfferLink };
  
  