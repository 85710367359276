import { closeDialog, resetDialogState, openDialog } from './actions';

const dialogMiddleware = (store) => (next) => (action) => {
  const { dialogs } = action;
  const { type } = action;

  if ([401, 402].includes(action?.error?.status)) {
    store.dispatch(openDialog('loginOrRegister'));
  }

  if (!dialogs || Array.isArray(type)) {
    return next(action);
  }

  if (type?.endsWith('_SUCCESS')) {
    if (dialogs.close) {
      store.dispatch(closeDialog(dialogs.close));
    }

    if (dialogs.closeAll) {
      store.dispatch(resetDialogState());
    }

    if (typeof dialogs?.open === 'string') {
      store.dispatch(openDialog(dialogs.open));
    }
  }

  if (type?.endsWith('_FAIL')) {
    if (typeof dialogs?.fail?.open === 'string') {
      store.dispatch(openDialog(dialogs.fail.open));
    }
  }

  return next(action);
};

export default dialogMiddleware;
